import { RedddotListData } from '../../../../common';
import { frontmanAxios } from '../../../axios';
import { AxiosRequestConfig } from 'axios'

export interface UpdateReddotParam {
  itemGroup: string;
  item: string;
  displayType: string;
  readDateTime: string;
}

/**
 * 해당 유저의 reddot 정보 업데이트
 * @see https://tmobi.atlassian.net/l/cp/cUe31i8p
 * @param params
 * @param config
 */
function updateReddot(params: UpdateReddotParam[], config: AxiosRequestConfig = {}) {
  return frontmanAxios.put<RedddotListData>(`/friday/api/v1/user/$userKey/badges`, { badges: params }, config)
}

export { updateReddot };
